import React from 'react';
import './SocialTray.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faGithubSquare,faLinkedin,faDev,faTwitterSquare} from '@fortawesome/free-brands-svg-icons';
library.add(faGithubSquare,faLinkedin,faDev);
class SocialTray extends React.Component{
    render(){
return <div id="socialtray" className={this.props.theme+"-socialtray"}>
<a href="https://github.com/prafulla-codes" rel="noopener noreferrer" target="_blank" aria-label="GitHub"><FontAwesomeIcon className="icon"  icon={faGithubSquare} size="3x" / ></a> 
<a href="https://www.linkedin.com/in/prafulla-raichurkar98/" rel="noopener noreferrer" target="_blank" aria-label="Linkedin"><FontAwesomeIcon className="icon" icon={faLinkedin} size="3x" / ></a>
<a href="https://dev.to/pika1998/" rel="noopener noreferrer" target="_blank"  aria-label="DEV.to"><FontAwesomeIcon className="icon" icon={faDev} size="3x" / ></a>
<a href="https://twitter.com/Prafulla98" rel="noopener noreferrer" target="_blank" aria-label="Twitter"><FontAwesomeIcon className="icon"  icon={faTwitterSquare} size="3x" /></a>

        </div>;
    }
}

export default SocialTray;