import React from 'react';
import './Landing.scss';
import SocialTray from '../socialtray/SocialTray';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';
class Landing extends React.Component{
    render(){
        let logo;
        if(this.props.theme==="light")
        {
            
            logo = <img loading="eager"  src="https://res.cloudinary.com/prafulla98/image/upload/c_scale,h_220,q_100,w_380,q_auto,f_auto/v1726903763/Portfolio/PRAFULLA_PHOTO_ROUNDED_df4m4k.png" alt="logo"></img>;
        }
        else
        {
            logo = <img loading="eager" src="https://res.cloudinary.com/prafulla98/image/upload/c_scale,h_220,q_100,w_380,q_auto,f_auto/v1726903763/Portfolio/PRAFULLA_PHOTO_ROUNDED_df4m4k.png" alt="logo"></img>;
        }
        return <div id="landing" className={this.props.theme+"-landing"}>
                <ScrollAnimation animateIn="animate__bounceInLeft" offset={50}  animateOnce={true}>
                {logo}
                </ScrollAnimation>
                <br></br>
                <ScrollAnimation animateIn="animate__fadeIn" delay={200} animateOnce={true} offset={100}>
                <h1>Prafulla Raichurkar</h1>
                <br/>
                <p id="MyRole"> Sr. Software Engineer @ Ingram Micro </p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__bounceInUp" delay={200}  animateOnce={true} initiallyVisible={false} offset={10} >
                <SocialTray theme={this.props.theme}/>
                </ScrollAnimation>
                </div>;
    }
}
export default Landing;