import React, { lazy, Suspense } from 'react';
import './App.scss';
import {lightBackground,darkBackground} from './_variables.scss';
import Landing from './components/landing/Landing';
import ThemeToggler from './components/themeToggler/ThemeToggler';
import NavWidget from './components/navwidget/NavWidget';
const Projects = lazy(()=> import('./components/projects/Projects'));
const About = lazy(()=> import('./components/about/About'));
const Skills = lazy(()=> import('./components/skills/Skills'));
const Hobbies = lazy(()=> import('./components/hobbies/Hobbies'));
const Footer = lazy(()=> import('./components/footer/Footer'));
const RenderLoader = () =>   <div className="loading">
<h2> Loading </h2>
</div>  
class App extends React.Component {
  constructor(props){
    super(props);
    let theme = localStorage.getItem("theme");
    if(theme==="auto") // If theme is set to auto OR null
    {
      var hr = (new Date()).getHours(); 
      if(hr>=19 || hr<7) // * Sets The Theme As Per The Current Time In Auto Mode
      {
        theme="dark"
      }
      else
      {
        theme="light"
      }
    }
    if(theme===null)
    {
      theme="dark";
    }
    this.state={theme:theme}
 
  }

  componentDidMount(){
    console.log("%c🙋‍♂️Hi There, looking for a Web Developer?",'font-size:1.5rem;font-weight:bold')
    console.log("%c💡Have a Project / Business Idea in mind? or just want to say Hi, Feel free to contact me at prafzzraichurkar1@gmail.com",'font-size:1.3rem;color:#0d47a1')


  }
  changeTheme = (theme)=>{
    localStorage.setItem("theme",theme);
    if(theme==="auto") // If theme is set to auto OR null
    {
      var hr = (new Date()).getHours(); 
      if(hr>=19 || hr<7)
      {
        theme="dark"
      }
      else
      {
        theme="light"
      }
    }
    if(theme===null){
      theme="dark"
    }
    this.setState({theme:theme}); // Changes the theme
  }
  render(){
 

    // Change Theme Of Address bar
    if(this.state.theme==="light")
    {
      document.querySelector('meta[name="theme-color"]').removeAttribute('content');
      document.querySelector('meta[name="theme-color"]').setAttribute('content',  `${lightBackground}`);
    }
    else
    {
      document.querySelector('meta[name="theme-color"]').removeAttribute('content');
      document.querySelector('meta[name="theme-color"]').setAttribute('content',  `${darkBackground}`);
    }
    // * Render the components as a SPA
    return <div className={'App app-'+this.state.theme}>
        
          <ThemeToggler theme={this.state.theme} changeTheme={this.changeTheme}></ThemeToggler>
          <NavWidget theme={this.state.theme}></NavWidget>
          <Landing theme={this.state.theme}></Landing>
          <Suspense fallback={<RenderLoader></RenderLoader>}>
          <About theme={this.state.theme}></About>
          </Suspense>
          <Suspense fallback={<RenderLoader></RenderLoader>}>
          <Skills theme={this.state.theme}></Skills>
          </Suspense>
          <Suspense fallback={<RenderLoader></RenderLoader>}>
          <Projects theme={this.state.theme}></Projects>
          </Suspense>
          <Suspense fallback={<RenderLoader></RenderLoader>}>
          <Hobbies theme={this.state.theme}></Hobbies>
          </Suspense>
          <Suspense fallback={<RenderLoader></RenderLoader>}>
          <Footer theme={this.state.theme}></Footer>
          </Suspense>
      </div>;
  }



}

export default App;
