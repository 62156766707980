import React from 'react';
import './NavWidget.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome , faCode , faInfoCircle,faFileAlt,faLaptopCode,faMagic,faTimesCircle} from '@fortawesome/free-solid-svg-icons'
library.add(faHome,faCode,faLaptopCode,faTimesCircle);
class NavWidget extends React.Component{
    constructor(){
        super()
        this.state={
            isOpen:false
        }
    }
    closeBtn = () => {
        if(window.innerWidth>800){
            document.getElementById("navwidget").style.animation="1s shrinkdesktop forwards";
            setTimeout(()=>{
                this.setState({isOpen:false})
                document.getElementById("burger").style.display="";
            },800)
        }
        else{
            document.getElementById("navwidget").style.animation="1s shrinkmobile forwards";
            setTimeout(()=>{
                this.setState({isOpen:false})
                document.getElementById("burger").style.display="";
            },500) 
        }
    }
    render(){
        return <div id="navwidget" className={this.props.theme+"-navwidget"}>
            <div id="burger">
                <hr id="line1" className="line"></hr>
                <hr id="line2" className="line"></hr>
                <hr id="line3" className="line"></hr>
            </div>
            {this.state.isOpen===true && (<div id="navButtonsContainer">
                <div className="nav-btn">
                <a href="#landing">
                <FontAwesomeIcon icon={faHome} size="2x"></FontAwesomeIcon>
                <h2>Home</h2>
                </a>
                </div>
                <a href="#projects">
                <div className="nav-btn">
                <FontAwesomeIcon icon={faCode} size="2x"></FontAwesomeIcon>
                <h2>Projects</h2>
                </div>
                </a>
                <a href="#about">
                <div className="nav-btn">
                <FontAwesomeIcon icon={faInfoCircle} size="2x"></FontAwesomeIcon>
                <h2>About Me</h2>
                </div>                
                </a>
                <a href={process.env.PUBLIC_URL+"/documents/CV.pdf"} target="_blank" rel="noopener noreferrer">
                <div className="nav-btn">
                <FontAwesomeIcon icon={faFileAlt} size="2x"></FontAwesomeIcon>
                <h2>View CV</h2>
                </div>
                </a>
                <a href="#skills">
                <div className="nav-btn">
                <FontAwesomeIcon icon={faLaptopCode} size="2x"></FontAwesomeIcon>
                <h2>Skills</h2>
                </div>
                </a>
                <a href="#hobbies">
                <div className="nav-btn">
                <FontAwesomeIcon icon={faMagic} size="2x"></FontAwesomeIcon>
                <h2>Hobbies</h2>
                </div>
                </a>
                <div id="close-btn" onClick={this.closeBtn}>
                <FontAwesomeIcon icon={faTimesCircle} size="2x"></FontAwesomeIcon>
                </div>
                 </div>)}
          
        </div>
    }
    componentDidMount(){

        document.getElementById("navwidget").addEventListener('click',()=>{
            if(!this.state.isOpen){
                if(window.innerWidth>800){
                    document.getElementById("navwidget").style.animation="1s expanddesktop forwards";
                    document.getElementById("burger").style.display="none"
                    this.setState({isOpen:true});
                }
                else{
                    document.getElementById("navwidget").style.animation="1s expandmobile forwards";
                    document.getElementById("burger").style.display="none"
                    this.setState({isOpen:true});
                }
            }
        })

    }
}

export default NavWidget;