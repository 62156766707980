import React from 'react';
import './ThemeToggler.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import {faSun,faMoon} from '@fortawesome/free-solid-svg-icons'
library.add(faSun)
class ThemeToggler extends React.Component{
render(){
   
    return <div id="themeToggler" className={this.props.theme+"-toggler"}>
             <span id="lightModeButton"> <FontAwesomeIcon icon={faSun} size="1x" / ></span>
             <span id="autoModeButton"> Auto </span>
             <span id="darkModeButton"><FontAwesomeIcon icon={faMoon} size="sm"/ ></span>
    </div>;
  
}

componentDidMount(){

     // Handler to set default selected button
     // Set AutoMode Button to Selected
     let storedTheme = localStorage.getItem("theme");

     if(storedTheme===null)
     {
        document.getElementById("darkModeButton").setAttribute("class","selected");
     }
     if(storedTheme==="auto")
     {
      document.getElementById("autoModeButton").setAttribute("class","selected");
     }
     if(storedTheme==="light")
     {
        document.getElementById("lightModeButton").setAttribute("class","selected");
     }
     if(storedTheme==="dark")
     {
        document.getElementById("darkModeButton").setAttribute("class","selected");
     }

     // Handler for LightMode Button
     document.getElementById("lightModeButton").addEventListener("click",()=>{
         document.getElementById("autoModeButton").removeAttribute("class");
         document.getElementById("lightModeButton").setAttribute("class","selected");
         document.getElementById("darkModeButton").removeAttribute("class");

         this.props.changeTheme("light");
     })

     // Handler for DarkMode Button
     document.getElementById("darkModeButton").addEventListener("click",()=>{
        document.getElementById("autoModeButton").removeAttribute("class");
        document.getElementById("lightModeButton").removeAttribute("class");
        document.getElementById("darkModeButton").setAttribute("class","selected");
        this.props.changeTheme("dark");
    })

     // Handler for AutoMode Button
     document.getElementById("autoModeButton").addEventListener("click",()=>{
        document.getElementById("lightModeButton").removeAttribute("class");
        document.getElementById("darkModeButton").removeAttribute("class");
        document.getElementById("autoModeButton").setAttribute("class","selected");
        this.props.changeTheme("auto");
     })
     
}
}

export default ThemeToggler;